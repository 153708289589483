
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTemplate } from '../../../assets/js/TemplateContext';
import { useNavigate } from 'react-router-dom';
import ClosedQuestion from '../Questions/ClosedQuestion';
import OpenQuestion from '../Questions/OpenQuestion';
import RatingQuestion from '../Questions/RatingQuestion';

const TemplatePreviewMessage = ({ onClose }) => {
    const { questions, template, participantes, fechaInicio, fechaFin } = useTemplate();
    console.log('Preguntas del contexto en TemplatePreviewMessage:', questions);
    const navigate = useNavigate();

    const renderQuestionComponent = (questionData, index) => {
        const adaptedQuestionData = {
            number: index + 1,
            question: questionData.pregunta,
            options: questionData.tipo === 'closed' ? questionData.opciones : undefined,
            minValue: questionData.tipo === 'rating' ? questionData.minValue : undefined,
            maxValue: questionData.tipo === 'rating' ? questionData.maxValue : undefined,
        };

        switch (questionData.tipo) {
            case 'open':
                return <OpenQuestion key={index} questionData={adaptedQuestionData} />;
            case 'closed':
                return <ClosedQuestion key={index} questionData={adaptedQuestionData} />;
            case 'rating':
                return <RatingQuestion key={index} questionData={adaptedQuestionData} />;
            default:
                return <div key={index}>Question type not supported</div>;
        }
    };

    const handleSaveAndGeneratePin = async () => {
        const surveyData = {
            nombreEncuesta: template.nombre,
            imagenEncuesta: template.imagen,
            preguntas: questions.map(question => ({
                textoPregunta: question.pregunta,
                tipoPregunta: question.tipo,
                opciones: question.tipo === 'closed' ? question.opciones : undefined,
                minValue: question.tipo === 'rating' ? question.minValue : undefined,
                maxValue: question.tipo === 'rating' ? question.maxValue : undefined,
            })),
            participantes: participantes,
            fechaInicio,
            fechaFin,
        };


        console.log('Datos a enviar:', surveyData.nombreEncuesta);
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No se encontró el token de autenticación.');
            return;
        }
        alert('Datos a enviar:', surveyData);
        try {
            const response = await fetch('http://localhost:3001/encuestas', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(surveyData),
            });
            console.log("problema 2")
            alert('Datos a enviar 2:', surveyData);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al enviar la encuesta');
            }

            const result = await response.json();
            console.log('Encuesta creada con éxito, PIN:', result.pin);

            navigate(`/dashboard`, { state: { pin: result.pin } });
        } catch (error) {
            console.error('Error al guardar la encuesta:', error);
        }
    };

    return (
        <div className="dynamic-survey-launch">
            <span>Vista previa</span>
            <div className="big-form-container-launch">
                <header className="diagnostic-name-survey">
                    <h2>{template.pin || 'Añadir Pin'}</h2> {/* Muestra el nombre de la encuesta aquí */}
                </header>
                <div>
                    <p>link para invitar a particpar</p>
                </div>
            </div>
            <div className="button-side">
                <button onClick={onClose} className="launch-button"><FontAwesomeIcon icon={faArrowLeft} />Volver</button>
                <button onClick={handleSaveAndGeneratePin} className="PIN-button">Generar PIN y Guardar 2</button>
            </div>
        </div>
    );
};

export default TemplatePreviewMessage;
