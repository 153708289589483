import React from 'react';
import '../../assets/styles/AboutUs.css';
import AboutUs from '../../assets/images/AboutUs.svg';
import ODS from '../../assets/images/ODS-círculo.png';


const AboutUs_0 = () => {
  return (
    <div className="about-container">
      <div className="about-section1">
            <h2>Sobre nosotros</h2>
            <p>Ofrecemos soluciones revolucionarias para las organizaciones. Únete a la comunidad de pulso y empieza a desarrollar y mejorar el bienestar de tu empresa.</p>
        </div>
        <div className="about-section">
            <img src={AboutUs}  c alt="AboutUsPage"></img>
        </div>
        <div className="about-section2">
            <h2>Nuestra Visión</h2>
            <p>Acompañar en el desarrollo en el bienestar organizacional construyendo organizaciones mas saludables que generen un impacto positivo en sus trabajadores, comunidad y sociedad en general.</p>
        </div>
        <img src={ODS}  c alt="AboutUsPage"></img>
    </div>
  );
}

export default AboutUs_0;
